var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-width",staticStyle:{"min-height":"600px"}},[_c('a-row',{staticStyle:{"margin":"20px auto","max-width":"1200px"},attrs:{"type":"flex"}},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/'}},[_c('a-icon',{attrs:{"type":"home"}}),_vm._v("首页 ")],1)],1),_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/user'}},[_vm._v("个人中心")])],1),_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":'/invoice/apply'}},[_vm._v("申请开票")])],1),_c('a-breadcrumb-item',[_vm._v("已开发票")])],1)],1),_c('CustomForm',{ref:"CustomForm",attrs:{"method":"getAllInvoiceForFranchisee","formItems":[
      { type: 'input', label: '发票单号', key: 'code', note: '按发票单号精确匹配' },
      { type: 'input', label: '产品名称', key: 'name', note: '按名称模糊匹配' },
      { type: 'select', label: '发票状态', key: 'status', default: 3, option: [
        { value: 2, label: '已申请待处理' },
        { value: 3, label: '已开票' } ] },
      { type: 'select', label: '发票类型', key: 'invoice_type', default: 1, option: [
        { value: 1, label: '增值税专用发票（纸质）' },
        { value: 2, label: '增值税普通发票（电子）' },
        { value: 3, label: '增值税普通发票（纸质）' } ] } ]}},[_c('div',{staticStyle:{"text-align":"left","margin":"20px 0"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.replace('apply')}}},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("申请开票 ")],1),_c('a-button',{staticStyle:{"margin":"0 15px"},on:{"click":function($event){return _vm.$router.replace('record')}}},[_vm._v("申请记录")])],1),_c('div',{staticClass:"search-result-list"},[_c('a-table',{staticStyle:{"margin":"20px"},attrs:{"columns":[
          { title: '发票单号', dataIndex: 'code' },
          { title: '开票金额', dataIndex: 'pay_money' },
          { title: '税额', dataIndex: 'tax_money' },
          { title: '开票状态', dataIndex: 'status', scopedSlots: { customRender: 'status'} },
          { title: '发票类型', dataIndex: 'invoice_type', scopedSlots: { customRender: 'invoice_type'} },
          { title: '申请时间', dataIndex: 'create_time' },
          { title: '开票时间', dataIndex: 'modify_time', scopedSlots: { customRender: 'modify_time'} },
          { title: '操作', scopedSlots: { customRender: 'action'} } ],"row-key":function (record) { return record.id_order_cart; },"data-source":_vm.data,"loading":_vm.spinning,"pagination":false},scopedSlots:_vm._u([{key:"status",fn:function(text){return _c('span',{},[_vm._v(_vm._s(_vm.status[text]))])}},{key:"invoice_type",fn:function(text){return _c('span',{},[_vm._v(_vm._s(_vm.invoice_type[text]))])}},{key:"modify_time",fn:function(text, record){return _c('span',{},[_vm._v(_vm._s(record.status === 3 ? text : ''))])}},{key:"action",fn:function(text, record){return _c('span',{},[(record.status === 2)?_c('a-button',{staticStyle:{"padding":"0"},attrs:{"type":"link"},on:{"click":function($event){return _vm.download(record)}}},[_c('a-icon',{attrs:{"type":"eye"}}),_vm._v("查看 ")],1):_vm._e()],1)}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }